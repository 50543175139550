<template>
  <div class="w-full flex flex-col items-start space-y-2">
    <label class="xs" v-if="variation === 'default'">
      {{ title }}
    </label>
    <div class="custom-input" :class="{
      'rounded-[5px] py-3': variation === 'default',
      'rounded-[10px] space-x-2': variation == 'search',
      'disabled-input': disabled,
    }">
      <div v-if="variation === 'search'">
        <IconComponent icon="akar-icons:search" />
      </div>
      <textarea class="outline-none w-full bg-transparent" autocomplete="off"
        v-if="type == 'textarea' && variation === 'default'" rows="5" :value="modelValue" :type="type" :name="inputName"
        :disabled="disabled" :required="required" :placeholder="placeholder || title"
        @input="$emit('update:modelValue', $event.target.value)"></textarea>
      <input v-else class="outline-none w-full bg-transparent" autocomplete="off" :min="min" :max="max" :type="type"
        :value="modelValue" :name="inputName" :disabled="disabled" :required="required" :minlength="minlength"
        :maxlength="maxlength" :placeholder="placeholder || title"
        @input="$emit('update:modelValue', $event.target.value)" @keyup.enter="$emit('keyup.enter')" />
    </div>
    <div v-if="maxlength" class="flex items-center w-full justify-end">
      <div class="rounded-full w-8 h-8 p-0.5 grid place-items-center relative" :style="{
        background: modelValue.length == maxlength ? '#3A9123' : `conic-gradient(#002BAB ${(modelValue.length / maxlength) * 100}%, #BEBEBE 0)`,
      }">
        <div class="bg-white rounded-full w-full h-full grid place-items-center z-10">
          <p class="text-sm text-gray-500">{{ maxlength - modelValue.length }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputComponent',
}
</script>

<script setup>
// eslint-disable-next-line
const props = defineProps({
  modelValue: {
    required: false,
    default: "",
  },
  title: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: "text",
  },
  placeholder: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  variation: {
    type: String,
    default: "default",
  },
  minlength: {
    type: Number,
    default: 0,
  },
  maxlength: {
    type: Number,
    required: false,
  },
  max: {
    type: String,
    required: false,
  },
  min: {
    type: String,
    required: false,
  },
});

const inputName = props.title.toLocaleLowerCase().replaceAll(" ", "-");

// eslint-disable-next-line
defineExpose({ inputName });
</script>