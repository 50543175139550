export class CreatePostDTO {
  constructor(post) {
    this.page = post.page;
    this.title = post.title;
    this.type = post.type;
    this.content = post.content;
    this.isPublished = post.isPublished;
    this.hashtags = post.hashtags;
  }

  createEmpty(page) {
    return new CreatePostDTO({
      page,
      title: "",
      content: "",
      type: "text",
      hashtags: [],
    });
  }
}

export class UpdatePostDTO extends CreatePostDTO {
  constructor(post) {
    super(post);
    delete this.page;
    this.id = post.id;
  }
}
