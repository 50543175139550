export default class Page {
  constructor(page) {
    this.id = page._id;
    this.title = page.title;
    this.profilePicture = page.profilePicture;
    this.coverPicture = page.coverPicture;
    this.description = page.description;
    this.email = page.email;
    this.phone = page.phone;
    this.isActive = page.isActive;
    this.level = page.level;
    this.followersCount = page.followersCount;
    this.dcc = page.dcc ? new DCC(page.dcc) : null;
    this.lcc = page.lcc ? new LCC(page.lcc) : null;
    this.nameOfLcb = page.nameOfLcb;
    this.createdAt = page.createdAt;
    this.updatedAt = page.updatedAt;
  }

  get followers() {
    return `${this.followersCount.toLocaleString()} follower${
      this.followersCount !== 1 ? "s" : ""
    }`;
  }

  get phoneNumber() {
    return `${this.phone.countryCode}${this.phone.number}`;
  }
}

export class DCC {
  constructor(dcc) {
    this.id = dcc._id;
    this.name = dcc.name;
    this.slug = dcc.slug;
    this.createdAt = dcc.createdAt;
    this.updatedAt = dcc.updatedAt;
  }
}

export class LCC {
  constructor(lcc) {
    this.id = lcc._id;
    this.name = lcc.name;
    this.slug = lcc.slug;
    this.createdAt = lcc.createdAt;
    this.updatedAt = lcc.updatedAt;
  }
}
