<template>
  <div class="">
    <h4>{{ text.title }}</h4>
    <form @submit.prevent="submitForm()" class="flex flex-col gap-4">
      <div v-if="!data.post" class="flex items-center gap-3">
        <div v-for="type in types" :key="type"
          class="border rounded-md py-1.5 px-4 cursor-pointer hover:bg-primary-500 hover:text-white hover:border-primary-500 transition-all duration-300"
          :class="post.type == type ? 'bg-primary-500 text-white border-primary-500' : ''" @click="post.type = type; post.content = ''">
          <p class="text-sm capitalize">{{ type }}</p>
        </div>
      </div>
      <div v-if="post.type == 'photo'" class="border-b pb-6 space-y-2">
        <div class="relative flex flex-col items-center space-y-4">
          <img class="w-full h-40 object-cover rounded-xl object-center" v-if="displayImage" :src="displayImage" />
          <div class="h-40 rounded-xl w-full bg-gray-200 grid place-items-center" v-else>
            <p class="text-center text-sm">Upload Photo</p>
          </div>
          <ButtonComponent :ordinary="true" :small="true" @click="openImageUploader('post-photo-image')">Upload
          </ButtonComponent>
          <input @change="uploadImage($event)" type="file" class="hidden" name="post-photo-image" id="post-photo-image"
            accept="image/*" />
        </div>
      </div>
      <InputComponent v-if="post.type == 'video'" type="url" :required="true" title="Paste video link"
        v-model="post.content" />
      <InputComponent :required="true" title="Title" v-model="post.title" :maxlength="90" />
      <div v-if="post.type == 'text'" class="flex flex-col gap-2">
        <label class="xs">
          Content
        </label>
        <div class="relative">
          <QuillEditor v-model:content="post.content" contentType="html" class="h-40 md:!h-60" theme="snow"
            :toolbar="toolbarOptions" />
        </div>
        <div class="flex items-center w-full justify-end">
          <div class="rounded-full w-8 h-8 p-0.5 grid place-items-center relative" :style="{
            background: post.content.length > maxContentLength ? '#F00' : `conic-gradient(#002BAB ${(post.content.length / maxContentLength) * 100}%, #BEBEBE 0)`,
          }">
            <div class="bg-white rounded-full w-full h-full grid place-items-center z-10">
              <p class="text-xs text-gray-500">{{ post.content.length > maxContentLength ? '0' : maxContentLength -
                post.content.length }}</p>
            </div>
          </div>
        </div>
      </div>
      <InputComponent :required="true" title="Hashtags" v-model="post.hashtags"
        placeholder="Enter hashtags e.g. #tag1 #tag-2" />
      <div class="flex items-center justify-between mt-6">
        <div>
          <ButtonComponent v-if="data.post && hasCreatorAccess" :ordinary="true" type="error" @click="deletePost()">
            Delete
          </ButtonComponent>
        </div>
        <div class="flex items-center space-x-4 ml-auto" v-if="!data.post">
          <ButtonComponent>Save Post</ButtonComponent>
          <ButtonComponent @click="submitForm(true)" :ordinary="true" type="success" v-if="hasCreatorAccess">
            {{ text.button }}
          </ButtonComponent>
        </div>
        <div class="flex items-center space-x-4" v-else>
          <ButtonComponent @click="togglePublish()" :ordinary="true" type="success"
            v-if="hasCreatorAccess && !post.isPublished">
            Publish
          </ButtonComponent>
          <ButtonComponent @click="togglePublish()" :ordinary="true" type="dark"
            v-if="hasCreatorAccess && post.isPublished">
            Unpublish
          </ButtonComponent>
          <ButtonComponent>{{ text.button }}</ButtonComponent>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "PostModal",
};
</script>

<script setup>
import { computed, inject, ref } from "@vue/runtime-core";
import Post from "@/models/post";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { CreatePostDTO, UpdatePostDTO } from "@/models/dtos/post-dto";
import { useStore } from "vuex";
import notificationService from "@/services/notification.service";
import { httpService } from "@/services/http.service";

// eslint-disable-next-line
const props = defineProps({
  data: {
    type: Post,
    required: false,
  },
});

const store = useStore();
const openModal = inject("openModal");

const types = ["text", "photo", "video"]
const maxContentLength = 900;

// eslint-disable-next-line
const emit = defineEmits(["close", "onClose", "closeWithFunction"]);

const propData = computed(() => {
  return props.data.post
    ? new UpdatePostDTO(props.data.post)
    : CreatePostDTO.prototype.createEmpty(props.data.page.id);
});
const user = computed(() => {
  return store.state.auth.user;
});

const post = ref(propData.value);
const displayImage = ref(post.value.content);

const publishPost = async (postID) => {
  const payload = { id: postID, isPublished: true }
  await store.dispatch("posts/update", payload);
};

const hasAdminAccess = computed(() => {
  return store.state.adminAccessRoles.includes(store.state.auth.user?.role);
});

const hasCreatorAccess = computed(() => {
  return store.state.creatorAccessRoles.includes(store.state.auth.user?.role);
});


const openImageUploader = (id) => {
  const image = document.getElementById(id);
  image.click();
};

const uploadImage = ($event) => {
  const image = $event.target.files[0];
  displayImage.value = URL.createObjectURL(image);
  post.value.content = image;
};

const fetchVideoLinkSEOInfo = async (link) => {
  const response = await httpService.get(`file/thumbnail`, { params: { link } })
  console.log(response.data);
}

const submitForm = async (publish) => {
  if (!post.value.content) {
    return notificationService.warning(
      "Please provide content for the post."
    );
  }

  if (post.value.content.length > maxContentLength) {
    return notificationService.warning(
      `Content limit exceeded. Please reduce the content to ${maxContentLength} characters.`
    );
  }

  const formData = new FormData();

  formData.append("content", post.value.content);
  formData.append("title", post.value.title);
  post.value.hashtags.split(" ").forEach(hashtag => {
    formData.append("hashtags[]", hashtag);
  });
  formData.append("type", post.value.type);
  formData.append("page", post.value.page);


  if (!props.data.post) {
    const response = await store.dispatch("posts/create", formData);
    if (publish) {
      await publishPost(response.data._id);
    }
    notificationService.success(response.message);
  } else {
    const message = await store.dispatch("posts/update", formData);
    notificationService.success(message);
  }
  await emit("closeWithFunction");
};

const text = computed(() => {
  return props.data.post
    ? { title: "Update post details", button: "Update" }
    : { title: "Create post", button: "Publish Post" };
});

const togglePublish = async () => {
  const payload = { id: post.value.id, isPublished: !post.value.isPublished }
  const message = await store.dispatch("posts/update", payload);
  notificationService.success(message);
  await emit("closeWithFunction");
};

const deletePost = () => {
  const closeFunction = store.state.modal.closeFunction;
  openModal(
    "delete",
    { type: "post", id: post.value.id },
    "medium",
    closeFunction
  );
};


const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ 'header': 1 }, { 'header': 2 }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],
  [{ 'indent': '-1' }, { 'indent': '+1' }],
  [{ 'size': ['small', false, 'large', 'huge'] }],
  [{ 'direction': 'rtl' }],
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['link', 'formula'],
  ['clean']
]

// eslint-disable-next-line
defineExpose({
  text,
  user,
  post,
  types,
  toolbarOptions,
  hasAdminAccess,
  hasCreatorAccess,
  fetchVideoLinkSEOInfo,
});
</script>
